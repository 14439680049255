export default [
  {
    path: "/active",
    name: "active",
    component: () => import("@/views/ActiveProject/index.vue"),
    meta: {
      pageTitle: "Active Projects",
      breadcrumb: [
        {
          text: "Active Projects",
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/active/:ActiveProjectId",
    name: "Active Project Summary",
    component: () => import("@/views/ActiveProject/ActiveProject.vue"),
    meta: {
      pageTitle: "Quotes",
      breadcrumb: [
        {
          text: "Active Project",
          active: true,
        },
        {
          text: "Summary",
          active: true,
        },
        {
          text: "Summary ",
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/active/:ActiveProjectId",
    name: "Active Project Finance",
    component: () => import("@/views/ActiveProject/ActiveProject.vue"),
    meta: {
      pageTitle: "Finance",
      breadcrumb: [
        {
          text: "Active Project",
          active: true,
        },
        {
          text: "Finance",
          active: true,
        },
        {
          text: "Finance ",
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/active/:ActiveProjectId",
    name: "Active Project Construction",
    component: () => import("@/views/ActiveProject/ActiveProject.vue"),
    meta: {
      pageTitle: "Construction",
      breadcrumb: [
        {
          text: "Active Project",
          active: true,
        },
        {
          text: "Project Construction",
          active: true,
        },
        {
          text: "Project Construction ",
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/active/:ActiveProjectId",
    name: "Active Project AV / IT",
    component: () => import("@/views/ActiveProject/ActiveProject.vue"),
    meta: {
      pageTitle: "AV / IT",
      breadcrumb: [
        {
          text: "Active Project",
          active: true,
        },
        {
          text: "AV / IT ",
          active: true,
        },
        {
          text: "AV / IT  ",
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/active/:ActiveProjectId",
    name: "Active Project FF&E",
    component: () => import("@/views/ActiveProject/ActiveProject.vue"),
    meta: {
      pageTitle: "FF&E",
      breadcrumb: [
        {
          text: "Active Project",
          active: true,
        },
        {
          text: "FF&E ",
          active: true,
        },
        {
          text: "FF&E  ",
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/active/:ActiveProjectId/:tfr_id",
    name: "Active Project Summary Page",
    component: () => import("@/views/ActiveProject/Components/Revision.vue"),
    meta: {
      pageTitle: "Revision",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
        {
          text: "Address",
          active: false,
        },
        {
          text: "Revisions",
          active: true,
        },
        {
          text: "Revision ",
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/active/:ActiveProjectId/co/:co_id",
    name: "Active Project CO Page",
    component: () => import("@/views/ActiveProject/Components/ChangeOrder.vue"),
    meta: {
      pageTitle: "Change Order",
      breadcrumb: [
        {
          text: "Active Project",
          active: false,
        },
        {
          text: "Revisions ",
          active: false,
        },
        {
          text: "Revisions  ",
          active: true,
        },
        {
          text: "Change order",
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
];
