import moment from "moment";
export const dateF = {
  formatDate: (date) => {
    // get dif days
    var a = moment();
    var b = moment(date);
    var diff = a.diff(b, "hours");
    if (diff < 23) {
      return moment(date).fromNow();
    } else {
      return moment(date).format("MMM, Do YYYY; LT");
    }
  },
};
