import Vue from "vue";
import VueRouter from "vue-router";
import active from "./active";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        pageTitle: "Home",
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/projects",
      name: "projects",
      component: () => import("@/views/Projects/Index.vue"),
      meta: {
        pageTitle: "Lead Projects",
        breadcrumb: [
          {
            text: "Lead Projects",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/quotes",
      name: "quotes",
      component: () => import("@/views/Quotes/Index.vue"),
      meta: {
        pageTitle: "Quotes",
        breadcrumb: [
          {
            text: "Quotes",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    ,
    {
      path: "/opportunities/:opportunityid/compare-quotes/:quoteid?",
      name: "Compare Quotes",
      component: () => import("@/views/QuotesCompareTool/Index.vue"),
      props: true,
      meta: {
        pageTitle: "Compare Quotes",
        breadcrumb: [
          {
            text: "gathering data",
            active: true,
          },
          {
            text: "Quotes",
            active: true,
          },
          {
            text: "Compare Quotes",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/opportunities/:opportunityid/:quoteid",
      name: "Quote Review",
      component: () => import("@/views/Quotes/Review.vue"),
      meta: {
        pageTitle: "Quote Review",
        breadcrumb: [
          {
            text: "gathering data",
            active: true,
          },
          {
            text: "Quotes",
            active: true,
          },
          {
            text: "",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/opportunities/:opportunityid/:quoteid/edit",
      name: "Edit Quote",
      component: () => import("@/views/Quotes/Edit.vue"),
      meta: {
        pageTitle: "Edit Quote",
        breadcrumb: [
          {
            text: "gathering data",
            active: true,
          },
          {
            text: "Quotes",
            active: true,
          },
          {
            text: " ",
            active: true,
          },
          {
            text: "Edit",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/opportunities/:opportunityid/:quoteid/n-edit",
      name: "Edit QuoteN",
      component: () => import("@/views/Quotes/NEdit.vue"),
      meta: {
        pageTitle: "Edit Quote",
        breadcrumb: [
          {
            text: "gathering data",
            active: true,
          },
          {
            text: "Quotes",
            active: true,
          },
          {
            text: " ",
            active: true,
          },
          {
            text: "Edit",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/opportunities/:opportunityid/:quoteid/itemized-edit",
      name: "Edit Itemized",
      component: () => import("@/views/Quotes/Components/ItemizedQuote/Edit.vue"),
      meta: {
        pageTitle: "Edit Quote",
        breadcrumb: [
          {
            text: "gathering data",
            active: true,
          },
          {
            text: "Quotes",
            active: true,
          },
          {
            text: " ",
            active: true,
          },
          {
            text: "Edit",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/opportunities",
      name: "opportunities",
      component: () => import("@/views/Opportunities/Index.vue"),
      meta: {
        pageTitle: "Opportunities",
        breadcrumb: [
          {
            text: "Opportunities",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/opportunities/:id",
      name: "Opportunity Review",
      component: () => import("@/views/Opportunities/Opportunity.vue"),
      meta: {
        breadcrumb: [
          {
            text: "Opportunities",
            active: true,
          },
          {
            text: "",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/opportunities/:id/tfr/:revision",
      name: "Opportunity Review Revisions",
      component: () => import("@/views/Opportunities/Opportunity.vue"),
      meta: {
        pageTitle: "Opportunity Review",
        breadcrumb: [
          {
            text: "Opportunities",
            active: true,
          },
          {
            text: "Opportunity Review",
            active: true,
          },
          {
            text: "",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/opportunities/:opportunityId/change-order/:changeOrderId",
      name: "Change Order Review",
      component: () => import("@/views/ChangeOrders/Review.vue"),
      meta: {
        pageTitle: "Change Order Review",
        breadcrumb: [
          {
            text: "Opportunities",
            active: true,
          },
          {
            text: "Change Order Review",
            active: true,
          },
          {
            text: "",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/opportunities/:opportunityId/change-order/:changeOrderId/edit",
      name: "Change Order Edit",
      component: () => import("@/views/ChangeOrders/Edit.vue"),
      meta: {
        pageTitle: "Edit",
        breadcrumb: [
          {
            text: "Opportunities",
            active: true,
          },
          {
            text: "Edit",
            active: true,
          },
          {
            text: "",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/catalogue",
      name: "catalogue",
      component: () => import("@/views/Catalogue/Index.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Spec Catalogue",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/catalogue/:id/edit",
      name: "catalogue-edit",
      component: () => import("@/views/Catalogue/Edit.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Catalogue",
            active: false,
          },
          {
            text: "Catalogue Edit",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/catalogue-addition",
      name: "catalogue-addition",
      component: () => import("@/views/AdditionsCatalogue/Index.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Additions Catalogue",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/catalogue-addition/:id/edit",
      name: "catalogue-edit-addition",
      component: () => import("@/views/AdditionsCatalogue/Edit.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Catalogue Addition",
            active: false,
          },
          {
            text: "Catalogue Edit Addition",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/catalogue/:id/edit-item",
      name: "catalogue-edit-item",
      component: () => import("@/views/Catalogue/EditSpecificationItem.vue"),
      meta: {
        pageTitle: "Edit Spec",
        breadcrumb: [
          {
            text: "Catalogue",
            active: false,
          },
          {
            text: "Spec Edit",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/opportunities_actions",
      name: "opportunities_actions",
      component: () => import("@/views/OpportunitiesActions/index.vue"),
      meta: {
        pageTitle: "Opportunity Management",
        breadcrumb: [
          {
            text: "Opportunity Management",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/compare-spec-sets",
      name: "Compare Spec Sets",
      component: () => import("@/views/SpecSetCompareTool/Index.vue"),
      props: true,
      meta: {
        pageTitle: "Compare Spec sets",
        breadcrumb: [
          {
            text: "gathering data",
            active: true,
          },
          {
            text: "Quotes",
            active: true,
          },
          {
            text: "Compare Spec sets",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/elements",
      name: "Elements",
      component: () => import("@/views/Elements/index.vue"),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Elements for (admins only)",
            active: true,
          },
        ],
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
    {
      path: "/custom-table",
      name: "Custom Table",
      component: () => import("@/views/CustomTable.vue"),
      meta: {
        pageTitle: "Custom Table",
        breadcrumb: [
          {
            text: "Opportunities",
            active: true,
          },
          {
            text: "Edit",
            active: true,
          },
          {
            text: "",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
    {
      path: "/completed",
      name: "completed",
      component: () => import("@/views/CompleteProjects/Index.vue"),
      meta: {
        pageTitle: "Completed Projects",
        breadcrumb: [
          {
            text: "Completed Projects",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    ...active,
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.

    const access_token = localStorage.getItem("token");
    if (access_token == null) {
      // user doesn't have access token, redirect to login
      next({ name: "login" });
    } else {
      // user has access token, user can open the page
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
